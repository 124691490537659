@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

::-webkit-scrollbar {
	max-width: 8px;
	max-height: 8px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: #ccc;
}
::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}
::-webkit-scrollbar-thumb:active {
	background: #888;
}
::-webkit-scrollbar-button {
	background: #ccc;
	height: 0;
	width: 0;
}

